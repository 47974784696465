import { settings } from '@/variables'

export default {
    data: () => ({
        homepage: settings.BaseURL + '/login'
    }),
    methods: {
        gotohome() {
            if (this.$store.getters.user) {
                this.$router.push(`/dashboard`)
            } else {
                this.$router.push(`/login`)
            }
        }
    }
}